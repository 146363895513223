import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LanguagesEnum, LocalStorageEnum } from '@enums';
import { DEFAULT_LANG } from '@helpers';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as HeaderActions from '@store';

@Component({
  selector: 'app-language-select',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './language-select.component.html',
  styleUrl: './language-select.component.scss',
})
export class LanguageSelectComponent implements OnInit {
  @Input() mode: 'mobile' | 'desktop' = 'desktop';
  @Input() isLoggedInUser: boolean = false;

  public lngList = Object.keys(LanguagesEnum).map((lng) => ({
    name: `enums.languages.${lng}`,
    value: lng,
    selected: false,
    imagePath: `./assets/image/flags/${lng}.svg`,
  }));
  public selectedLanguage = { ...this.lngList[0] };

  constructor(
    private translate: TranslateService,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    const currentLanguage = localStorage.getItem(LocalStorageEnum.CURRENT_LANGUAGE) ?? DEFAULT_LANG;
    this.lngList.forEach((lng) => {
      if (lng.value === currentLanguage) {
        this.selectedLanguage = { ...lng };
        lng.selected = true;
      } else {
        lng.selected = false;
      }
    });
  }

  public selectLanguage(e, newLang): void {
    e.preventDefault();
    localStorage.setItem(LocalStorageEnum.CURRENT_LANGUAGE, newLang.value);

    this.translate.use(newLang.value).subscribe(() => {
      this.store.dispatch(HeaderActions.languageChanged({ lang: newLang.value }));

      this.lngList.forEach((lng) => {
        lng.selected = false;
        if (lng.value === newLang.value) {
          lng.selected = true;
          this.selectedLanguage = { ...lng };
        }
      });
    });
  }

  public selectedClick(e: any): void {
    e.preventDefault();
    e.stopPropagation();
  }
}
