import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Header } from '@dictionaries';
import { FilterNameEnum, LocalStorageEnum, ModalEnum, PropertyHomeTypeEnum } from '@enums';
import { BRAND_APP } from '@helpers';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService, injectDestroyService, ModalsService, provideDestroyService } from '@service';
import * as FilterActions from '@store';
import { selectHeaderLogged } from '@store';
import * as HeaderActions from '@store';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from '../../services/local-storage.service';
import { LanguageSelectComponent } from '../language-select/language-select.component';

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [LanguageSelectComponent, NgForOf, NgIf, RouterLink, TranslateModule, NgClass],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
  providers: [provideDestroyService()],
})
export class MobileMenuComponent implements OnDestroy {
  public header = new Header();
  public headerMenu = this.header.menu();
  public isLoggedUser: boolean = false;
  public readonly addNewItem = { name: 'header.sell', path: '/sell-property' };

  protected readonly BRAND_APP = BRAND_APP;

  private readonly destroy$ = injectDestroyService();

  constructor(
    public store: Store,
    private el: ElementRef,
    private modalsService: ModalsService,
    private renderer: Renderer2,
    private router: Router,
    private localStorageService: LocalStorageService,
    private auth: AuthService,
    private translate: TranslateService,
  ) {
    this.store.pipe(select(selectHeaderLogged), takeUntil(this.destroy$)).subscribe((res) => {
      this.isLoggedUser = res;

      if (res) {
        this.headerMenu = this.header.addUserMenu();
      } else {
        this.headerMenu = this.header.removeUserMenu();
      }
    });
  }

  ngOnDestroy() {
    this.hideChildMenu();
  }

  public closeMobileMenu(event: any) {
    if (event.target.tagName !== 'A') {
      return;
    }

    this.hideChildMenu();
    this.modalsService.close(ModalEnum.MOBILE_MENU);
  }

  public setFilter(params?: { propertyType: string; homeType?: string }) {
    if (!params) {
      return;
    }

    let data = {
      [FilterNameEnum.propertyType]: params.propertyType,
      [FilterNameEnum.homeType]: params?.homeType
        ? [params.homeType]
        : Object.keys(PropertyHomeTypeEnum).map((k) => PropertyHomeTypeEnum[k]),
    };

    this.store.dispatch(FilterActions.filterChanged({ data }));
  }

  public openChildMenu(idx: number) {
    const parents = this.el.nativeElement.querySelectorAll('.mobileMenuContainer .arrow');
    const items = this.el.nativeElement.querySelectorAll('.mobileMenuContainer .child');

    parents.forEach((item, index) => {
      if (index === idx && !parents[idx].classList.contains('opened-children')) {
        this.renderer.addClass(item, 'opened-children');
      } else {
        this.renderer.removeClass(item, 'opened-children');
      }
    });

    items.forEach((item, index) => {
      if (index === idx && items[idx].classList.contains('hide')) {
        this.renderer.removeClass(item, 'hide');
        this.renderer.setStyle(item, 'border-top', '1px solid #e0e0e0');
      } else {
        this.renderer.addClass(item, 'hide');
      }
    });
  }

  public logout() {
    this.auth.signOut().subscribe((_) => {
      this.localStorageService.removeItem(LocalStorageEnum.ACCESS_TOKEN);
      this.store.dispatch(HeaderActions.updateLoggedState({ data: false }));
      this.isLoggedUser = false;
      this.router.navigate(['/']);
      this.modalsService.close(ModalEnum.MOBILE_MENU);
    });
  }

  private hideChildMenu() {
    const opened = this.el.nativeElement.querySelectorAll('.opened-children');
    const items = this.el.nativeElement.querySelectorAll('.mobileMenuContainer .child');

    opened.forEach((item) => {
      this.renderer.removeClass(item, 'opened-children');
    });

    items.forEach((item) => {
      this.renderer.addClass(item, 'hide');
    });
  }
}
